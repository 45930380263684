// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-disclaimer-en-js": () => import("./../../../src/pages/disclaimer.en.js" /* webpackChunkName: "component---src-pages-disclaimer-en-js" */),
  "component---src-pages-imprint-en-js": () => import("./../../../src/pages/imprint.en.js" /* webpackChunkName: "component---src-pages-imprint-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-templates-blogs-template-js": () => import("./../../../src/templates/blogsTemplate.js" /* webpackChunkName: "component---src-templates-blogs-template-js" */),
  "component---src-templates-business-template-js": () => import("./../../../src/templates/businessTemplate.js" /* webpackChunkName: "component---src-templates-business-template-js" */),
  "component---src-templates-cases-template-js": () => import("./../../../src/templates/casesTemplate.js" /* webpackChunkName: "component---src-templates-cases-template-js" */),
  "component---src-templates-columns-template-js": () => import("./../../../src/templates/columnsTemplate.js" /* webpackChunkName: "component---src-templates-columns-template-js" */),
  "component---src-templates-contact-adress-template-js": () => import("./../../../src/templates/contactAdressTemplate.js" /* webpackChunkName: "component---src-templates-contact-adress-template-js" */)
}


import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { theme } from './src/theme/theme';


export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  src: url("DK_Roboto_55_Regular.ttf") format("ttf");
  font-display: swap;
}
@font-face {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  src: url("DK_Roboto_35_Thin.ttf") format("ttf");
  font-display: swap;
}
@font-face {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  src: url("DK_Roboto_65_Regular.ttf") format("ttf");
  font-display: swap;
}
@font-face {
  ffont-family: "Roboto", sans-serif;
  font-weight: 700;
  src: url("DK_Roboto_75_Bold.ttf") format("ttf");
  font-display: swap;
}
@font-face {
  ffont-family: "Roboto", sans-serif;
  font-weight: 800;
  src: url("DK_Roboto_85_Black.ttf") format("ttf");
  font-display: swap;
}

  *, *::before, *::after {
    box-sizing: border-box;
  }
  html {
    font-family: "Roboto", sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    touch-action: manipulation;
    background-color: ${({ theme }) => theme.colors.white};
  }
  body {
    margin: 0;
  }
  main {
    flex: 1 0 auto;
  }
  h1 {
    font-size: ${({ theme }) => theme.fontSize.xLarge};
  }
`

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={ theme }>
    <GlobalStyle />
    { element }
  </ThemeProvider>
)
export const theme = {
  colors: {
    primary: '#f28e00',
    secondary: '#2B2E34',
    white: '#ffffff',
    grayDark: '#BDBDBD',
    grayLight: '#ECECEC',
    footerBg: '#8D8D8D',
  },
  fontWeight: {
    thin: 300,
    regular: 400,
    bold: 500,
  },
  fontSize: {
    xSmall: '11px',
    small: '15px',
    regular: '18px',
    lagre: '30px',
    xLarge: '40px',
  }
};

//animations
//spacing




//breakpoints
//large-mobile= 
//tablet = 1024px
//